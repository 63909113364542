<template>
  <div>
    <!-- Select Box for Zoom Levels -->
    <b-row class="mx-2 my-1">
      <b-form-group>
        <label for="zoom-level">Report Font Size Settings:</label>
        <b-form-select class="mr-1" style="width: 200px;" v-model="reportFontsize" :options="fontsizeOptions"
          value-field="value" text-field="text">
        </b-form-select>
      </b-form-group>
    </b-row>

    <!-- Update Button -->
    <b-row class="mx-2 my-1">
      <b-button variant="primary" @click="setUserGroupsInstructorFeedbackSurveyReportFontsize">Update</b-button>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["usergroup_id"],
  async created() {
    await this.getUserGroupsInstructorFeedbackSurveyReportFontsize();
  },
  data() {
    return {
      fontsizeOptions: [
        { value: 9, text: "~9pt" },
        { value: 10, text: "~10pt" },
        { value: 11, text: "~11pt" },
        { value: 12, text: "~12pt (default)" },
        { value: 13, text: "~13pt" },
        { value: 14, text: "~14pt" }
      ],
      reportFontsize: 12, // Set as a number
    };
  },
  methods: {
    getUserGroupsInstructorFeedbackSurveyReportFontsize() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/setting/${this.usergroup_id}/instructor_feedback_survey_report_fontsize`;
      this.$axios
        .get(url)
        .then((response) => {
          this.reportFontsize = response.data;
        });
    },

    async setUserGroupsInstructorFeedbackSurveyReportFontsize() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/setting/${this.usergroup_id}/instructor_feedback_survey_report_fontsize`;
      await this.$axios
        .post(url, { instructor_feedback_survey_report_fontsize: this.reportFontsize })
        .then(() => {
          this.$toast.success("Updated", {
            position: "top",
            duration: 3000,
          });
        });
    },
  },
};
</script>
