<template>
  <div class="container">
    <!-- Vision Input Field -->
    <h2 class="subgroupname">{{ usergroup_name }}</h2>
    <b-row class="my-2">
      <b-col>
        <b-form-group>
          <template v-slot:label>
            <span class="title">
              VISION
            </span>
            <i style="font-size: 16px!important;color: #007bff;" v-if="!isVisionEditable" @click="toggleVisionEdit" class="far fa-edit"></i>
          </template>
          <b-form-textarea 
            v-model="vision" 
            placeholder="Enter vision..." 
            rows="4" 
            class="full-width-textarea"
            :readonly="!isVisionEditable">
          </b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Mission Input Field -->
    <b-row class="my-2">
      <b-col>
        <b-form-group>
          <template v-slot:label>
            <span class="title">
              MISSION
            </span>
            <i style="font-size: 16px!important;color: #007bff;" v-if="!isMissionEditable" @click="toggleMissionEdit" class="far fa-edit"></i>
          </template>
          <b-form-textarea 
            v-model="mission" 
            placeholder="Enter mission..." 
            rows="4" 
            class="full-width-textarea"
            :readonly="!isMissionEditable">
          </b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Update Button -->
    <b-row class="my-2">
      <b-col class="text-right">
        <b-button variant="primary" @click="setUserGroupsVisionMission">Update</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["usergroup_name", "usergroup_id"],
  async created() {
    await this.getUserGroupsVision();
    await this.getUserGroupsMission();
  },
  data() {
    return {
      vision: "",
      mission: "",
      isVisionEditable: false,
      isMissionEditable: false,
    };
  },
  methods: {
    async getUserGroupsVision() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/setting/${this.usergroup_id}/vision`;
      try {
        const response = await this.$axios.get(url);
        this.vision = response.data;
      } catch (error) {
        error;
      }
    },
    async getUserGroupsMission() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/setting/${this.usergroup_id}/mission`;
      try {
        const response = await this.$axios.get(url);
        this.mission = response.data;
      } catch (error) {
        error;
      }
    },

    async setUserGroupsVisionMission() {
      if(this.isVisionEditable){
        await this.setUserGroupsVision();
      } 
      if(this.isMissionEditable){
        await this.setUserGroupsMission();
      }
    },
    async setUserGroupsVision() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/setting/${this.usergroup_id}/vision`;
      try {
        await this.$axios.post(url, {
          vision: this.vision,
        });
        this.$toast.success("Vision Updated", {
          position: "top",
          duration: 3000,
        });
        this.isVisionEditable = false; // Disable editing after update
      } catch (error) {
        error;
      }
    },

    async setUserGroupsMission() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/setting/${this.usergroup_id}/mission`;
      try {
        await this.$axios.post(url, {
          mission: this.mission,
        });
        this.$toast.success("Mission Updated", {
          position: "top",
          duration: 3000,
        });
        this.isMissionEditable = false; // Disable editing after update
      } catch (error) {
        error;
      }
    },

    toggleVisionEdit() {
      this.isVisionEditable = !this.isVisionEditable;
    },

    toggleMissionEdit() {
      this.isMissionEditable = !this.isMissionEditable;
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 100%;
  padding: 20px;
}

.title {
  font-size: 18px;
  text-decoration: underline;
  font-weight: bold;
  color: #007bff;
}

.full-width-textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  min-height: 100px;
  resize: none;
}

.subgroupname {
  font-weight: bold;
  text-align: center;
  color: #007bff; /* Bootstrap primary color */
  text-transform: uppercase;
}
</style>
