<template>
  <div>
    <!-- <p>
     Attainment inputs=> datas used to generate attainment report. eg: score of  a student in an assessment question 
Assessment Tools => assessment, assignment, survey, university exam
Direct Assessment Tools => assessment, assignment, university exam
Indirect Assessment Tools => Survey



Choose Course outcomes: 
the selected course outcomes will be considered for generating attainment report, 
also in each module (assessment, assignment, survey, etc..) attainment inputs that are linked to these course outcomes will be used for generating attainment 

Confirm selected CO's 

Choose Students: Attainment inputs from the selected students will be considered for report

confirm Students selection

Attainment Config: here you can configure the attainment calculation constants 
or leave with default values if you dont want to customize
first part=> To find the Direct attainment level of a Course outcome after calculating the attainment level of each direct assessment Tool
by default each Assessment Tools have Equal Weightage on Direct attainment level or you can configure here
second => when finding the Final Attainment level of a CO, you can configure the weightage Direct and indirect attainment level
third => 


Activity View Data => each column contain score of a student in a question(question number, related CO, Activity name) 
Activity View Report => each column contain % of total score of a student in a CO. 
% of student in CO1 = [(score of student in question1 linked to CO1/ maximum mark of question1) + 
(score of student in question2 linked to CO1/ maximum mark of question2)+... ]/number of questions linked to CO1  
Target Achieved Student Count for CO1 = number of student score in CO1 grated than target value<input>
Target Achieved Student % = Target Achieved Student Count * 100 / Max Students Count
Attainment Level = compare the Target Achieved Student % with Attainment level table<input>

Survey : Avg. value of a question =  [ (score of choice1 * count of choice1) + (score of choice2 * count of choice2) + ... ] / sum of count or no. of students
Survey : % of a question =  [ (score of choice1 * count of choice1) + (score of choice2 * count of choice2) + ... ] / (sum of count * maximum score of choice in question)
Survey : attainment level of CO1 = 3/ 100 * average of % of a questions linked to CO1

university exam :
Target Achieved Student Count = number of student score grated than target value<input>
Target Achieved Student % = Target Achieved Student Count * 100 / Max Students Count
Attainment Level of CO (all CO have same value) = compare the Target Achieved Student % with Attainment level table<input>

Final Attainment value:

Final Attainment value of CO1 (equal assessment tool weightage) =  [Direct weightage/100 * ([assessment+assignment+university exam]/3)] + (Indirect weightage/100 * survey)

Final Attainment value of CO1 (custom assessment tool weightage) =  [Direct weightage/100 * (
[(assessment*assessment weightage/100) + 
(assignment*assignment weightage/100)+
(universityExam*universityExam weightage/100)
]/3
)] + (Indirect weightage/100 * survey)


    </p> -->
    <div class="courseReports">
      <b-overlay :show="loadData" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
        <div class="attainment">
          <b-row>
            <b-col md="12">
              <div class="d-flex justify-content-between">
                <b-button variant="danger" @click="goBack" size="md" :disabled="activeAttainment == 0">Back</b-button>
                <b-button variant="primary" :disabled="this.buttondisable" size="md" @click="proceedToNext">{{
                    activeAttainment == attaimentOptions.length - 1
                      ? "Save & Finish"
                      : "Next"
                }}</b-button>
              </div>
              <br />
            </b-col>
            <b-col md="12">
              <b-list-group horizontal>
                <b-list-group-item v-for="(attainment, attainmentIndex) in attaimentOptions" :key="attainmentIndex"
                  :active="activeAttainment == attainmentIndex ? true : false">
                  {{ attainment.name }}
                </b-list-group-item>
              </b-list-group>
              <br />
            </b-col>
            <b-col md="12">
              <component v-bind:is="currentComponent" :courseReport="courseReport" :courseDetails="course"
                :ref="currentComponent"></component>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import CourseOutcomes from "./CourseOutcomes.vue";
import StudentList from "./StudentList.vue";
import Assignments from "./Assignments.vue";
import Assessments from "./Assessments.vue";
import Survey from "./Survey.vue";
import Laboratory from "./laboratory.vue";
import CoPoPsoMapping from "./CoPoPsoMapping.vue";
import BoardExams from "./BoardExams.vue";
import TheAttainment from "./TheAttainment.vue";
import AttainmentReport from "./AttainmentReport.vue";
export default {
  props: ["courseReport"],
  async created() {
    this.loadData = true;
    // console.log(this.attaimentOptions);
    await this.getCourse();
    if (this.course.type) {
      if (this.course.type.slug != 'laboratory') {
        // console.log(this.attaimentOptions);
        this.attaimentOptions.splice(5, 1);
      }
    }
    this.loadData = false;
  },
  components: {
    CourseOutcomes,
    StudentList,
    Assignments,
    Assessments,
    Survey,
    BoardExams,
    TheAttainment,
    AttainmentReport,
    Laboratory,
    CoPoPsoMapping,
  },
  data() {
    return {
      loadData: false,
      buttondisable:false,
      attaimentOptions: [
        { name: "Course Outcomes", component: "CourseOutcomes" },
        { name: "Students List", component: "StudentList" },
        { name: "Configure", component: "TheAttainment" },
        { name: "Assignments", component: "Assignments" },
        { name: "Assessments", component: "Assessments" },
        { name: "Laboratory", component: "Laboratory" },
        { name: "University Exams", component: "BoardExams" },
        { name: "Survey", component: "Survey" },
        { name: "CO PO PSO Mapping", component: "CoPoPsoMapping" },
        { name: "Course CO Attainment", component: "AttainmentReport" },
      ],
      activeAttainment: 0,
      currentComponent: "CourseOutcomes",
      course: {},
    };
  },
  computed: {
    handleComponent() {
      if (
        this.currentComponent != "" &&
        this.$refs[this.currentComponent] &&
        this.$refs[this.currentComponent].config == true
      ) {
        alert();
        return true;
      } else {
        console.log(this.$refs[this.currentComponent]);
        return false;
      }
    },
  },
  methods: {
    async proceedToNext() {   
      const reportComponent = await this.$refs[this.currentComponent];

      this.loadData = true;     
      if (
        this.activeAttainment != this.attaimentOptions.length - 1 &&
        reportComponent.config
      ) {
        const data = await reportComponent.setAttainmentConfigration();
        data;
        if (["TheAttainment", "CourseOutcomes", "StudentList"].includes(this.currentComponent)) {
            if (this.currentComponent === "TheAttainment") {
            if (reportComponent.proceedNextCheck()) {
              this.activeAttainment = (await this.activeAttainment) + 1;
              this.currentComponent = await this.attaimentOptions[this.activeAttainment].component;
            } else {
              this.buttondisable = true;
            }
          } else {
            this.activeAttainment = (await this.activeAttainment) + 1;
            this.currentComponent = await this.attaimentOptions[this.activeAttainment].component;
          }
        }

        // if (reportComponent.skip !=undefined && reportComponent.skip==true) {
        //   this.activeAttainment = (await this.activeAttainment) + 1;
        //   this.currentComponent = await this.attaimentOptions[
        //     this.activeAttainment
        //   ].component;
        // }else{
        if (reportComponent.getAttainmentData) {
          await reportComponent.getAttainmentData();
        }
        if (reportComponent.getAttainmentReport) {
          await reportComponent.getAttainmentReport();
        }
        // }
        reportComponent.config = false;

        await this.$refs.currentComponent;
      } else if (this.activeAttainment != this.attaimentOptions.length - 1) {
        if (reportComponent.report != undefined && !reportComponent.report) {
          this.loadData = false;
          return (reportComponent.report = true);
        }
        this.activeAttainment = (await this.activeAttainment) + 1;
        this.currentComponent = await this.attaimentOptions[
          this.activeAttainment
        ].component;
      } else {
        reportComponent.setAttainmentConfigration();
        this.saveAttainmentConfigs();
        this.$emit("finishAttainment");
      }
      this.loadData = false;
    },
    async saveAttainmentConfigs() {
        const url =
            this.$store.getters.getAPIKey.mainAPI +
            `/coattainment/savedata`;
        await this.$axios
            .post(url, {
                course: this.courseReport.course_id
            })
            .then(() => {
                this.$toast.success('Configuration saved successfully', {
                    position: "top",
                    duration: 3000,
                });
            })
            .catch(() => {
                this.$toast.error('Failed save configuration', {
                    position: "top",
                    duration: 5000,
                });
            });
        },
    async goBack() {
      this.buttondisable=false;
      const reportComponent = await this.$refs[this.currentComponent];
      if (reportComponent.config != undefined && !reportComponent.config) {
        reportComponent.config = true;
      } else {
        this.activeAttainment = (await this.activeAttainment) - 1;
        this.currentComponent = await this.attaimentOptions[
          this.activeAttainment
        ].component;
      }
    },

    async getCourse() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/courses/${this.courseReport.course_id}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.course = response.data;
        });
    },
  },
};
</script>

<style >
.attainment .list-group .list-group-item {
  font-family: "Roboto Regular";
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  font-size: 14px;
  font-weight: 600;
}

.courseReports .attainment .table-responsive {
  max-height: 400px;
}
</style>
