<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="coursesOptions" v-else>
      <b-row>
        <b-col md="12">
          <b-card>
            <div class="courseDetails d-flex justify-content-between flex-wrap">
              <div class="subgroup d-flex">
                <h6 class="text-info ml-2">
                  {{ usergroup_name }}
                </h6>
              </div>
            </div>
          </b-card>
          <br />
        </b-col>
        <b-col md="2">
          <b-list-group>
            <b-list-group-item :active="currentOption == 0 ? true : false"
              @click="switchUsergroupComponent('Activity', 0)">
              Activity Name Settings
            </b-list-group-item>
            <b-list-group-item :active="currentOption == 1 ? true : false"
              @click="switchUsergroupComponent('Reports', 1)">
              Report Signature Settings
            </b-list-group-item>
            <b-list-group-item :active="currentOption == 2 ? true : false"
              @click="switchUsergroupComponent('ReportFontSize', 2)">
              Instructor Feedback Survey Report Font Size Settings
            </b-list-group-item>
            <b-list-group-item :active="currentOption == 3 ? true : false"
              @click="switchUsergroupComponent('VisionAndMission', 3)">
              Vision And Mission
            </b-list-group-item>
            <b-list-group-item :active="currentOption == 4 ? true : false"
              @click="switchUsergroupComponent('Attainment', 4)">
              Attainment Calculation Method
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col md="10">
          <component v-bind:is="currentComponent" :usergroup_name ="usergroup_name" :usergroup_id="usergroup_id"></component>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Activity from "./ActivityNameSetting.vue";
import Reports from "./ReportSignatureSettings.vue";
import ReportFontSize from "./ReportFontSizeSettings.vue";
import VisionAndMission from "./VisionAndMission.vue";
import Attainment from "./AttainmentCalculationMethods.vue";
export default {
  props: [
    "usergroup_name",
    "usergroup_id"
  ],
  async created() {
    this.currentComponent = 'Activity';
  },
  components: {
    Activity,
    Reports,
    ReportFontSize,
    VisionAndMission,
    Attainment
  },
  data() {
    return {
      fetchData: false,
      currentOption: 0,
      currentComponent: "",
      course: {}
    };
  },
  computed: {
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
  },
  methods: {
    switchUsergroupComponent(selectedComponent, optionIndex) {
      this.currentComponent = selectedComponent;
      this.currentOption = optionIndex;
    },
  },
};
</script>

<style>
.coursesOptions .list-group .list-group-item {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #007bff;
  color: #ffffff;
  border-radius: 10px;
}

.coursesOptions .list-group .list-group-item.active {
  color: #007bff;
  background-color: #ffffff;
}
</style>