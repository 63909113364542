<template>
  <div>
    <div class="assessment border border-info rounded p-2">
      <div
        class="checkAssignment d-flex justify-content-between align-items-baseline mb-2"
      >
        <b-row>
          <b-col class="sm-12">
            <div class="d-flex">
              <b-form-checkbox class="ml-2" v-model="assessment.checkAssessment"
                ><p class="btn p-0 m-0 text-info">
                  Assessment
                </p></b-form-checkbox
              >
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sm-6">
            <div class="" v-if="assessment.checkAssessment">
              <label for="scoreForAssessment">Score:</label>
              <b-form-input
                id="scoreForAssessment"
                v-model="assessment.scoreForAssessment"
                placeholder="Enter Score"
                :class="{
                  'form-group-error': $v.assessment.scoreForAssessment.$error,
                }"
              ></b-form-input>
              <span
                class="input-error-message"
                v-if="
                  !$v.assessment.scoreForAssessment.required &&
                  $v.assessment.scoreForAssessment.$dirty
                "
                >Score is mandatory!</span
              >
            </div>
          </b-col>
          <b-col class="sm-6">
            <div class="" v-if="assessment.checkAssessment">
              <label for="best_of_scores">Best of score:</label>
              <b-form-input
                id="best_of_scores"
                v-model="assessment.best_of_scores"
                placeholder="Optional"
                type="number"
                min="0"
                value=""
              ></b-form-input>
              <small id="best_of_scores" class="form-text text-muted"
                >Number of best scores will be considered for total score</small
              >
            </div>
          </b-col>
        </b-row>
        <span></span>
      </div>
      <b-collapse
        id="assessmentConfig"
        v-model="assessment.checkAssessment"
        class="mt-2"
      >
        <div class="selectScoreType mb-2">
          <span class="text-dark font-weight-bolder">Choose Score Type:</span>
        </div>
        <table class="table table-bordered table-striped">
          <thead class="thead-dark">
            <tr>
              <th></th>
              <th>Assessment</th>
              <th v-if="!activity_simple_calculation">Percent Weightage</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="assessmentsFromApi.length == 0">
              <td colspan="100" class="text-danger text-center align-middle">
                No Data Found!
              </td>
            </tr>
            <tr
              v-for="(assessment, assessmentIndex) in assessmentsFromApi"
              :key="assessmentIndex"
              v-else
            >
              <td class="align-middle">
                <b-form-checkbox
                  size="sm"
                  v-model="assessmentConfigStructure[assessment.id].checkbox"
                >
                </b-form-checkbox>
              </td>
              <td class="align-middle">
                {{ assessment.name }}
              </td>

              <td
                v-if="!activity_simple_calculation"
                class="text-center align-middle"
              >
                <b-form-input
                  v-if="assessmentConfigStructure[assessment.id].checkbox"
                  placeholder="Enter Percentage"
                  v-model="assessmentConfigStructure[assessment.id].input"
                  @blur="$v.assessmentConfigStructure.$each.$touch()"
                ></b-form-input>
                <!-- Show validation error for input -->
                <div v-if="assessmentConfigStructure[assessment.id].checkbox && $v.assessmentConfigStructure.$each[assessment.id].input.$error" class="text-danger">
                  <span v-if="!$v.assessmentConfigStructure.$each[assessment.id].input.required">This field is required.</span>
                  <span v-if="!$v.assessmentConfigStructure.$each[assessment.id].input.numeric">Value must be numeric.</span>
                  <span v-if="!$v.assessmentConfigStructure.$each[assessment.id].input.customValidation">
                    All inputs must either be the same or sum up to 100.
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
required;
import moment from "moment";
export default {
  props: ["saved_configs", "courseReport", "activity_simple_calculation"],
  async created() {
    await this.getAssessments();
    let savedExamConfig = this.saved_configs.activity_catagory_configs.exams;
    if (savedExamConfig.enable) {
      this.assessment = {
        checkAssessment: savedExamConfig.enable,
        scoreForAssessment: savedExamConfig.score,
        best_of_scores: savedExamConfig.best_of_scores,
      };
      let selectedExams = savedExamConfig.resourses.exam;

      for (const key in selectedExams) {
        if (key in this.assessmentConfigStructure) {
          this.assessmentConfigStructure[key].checkbox = true;
          this.assessmentConfigStructure[key].input =
            selectedExams[key].persantage_of_wightage;
        }
      }
    }
    this.assessment.checkAssessment = savedExamConfig.enable;
  },
  data() {
    return {
      fetchData: true,
      assessment: {
        checkAssessment: false,
        scoreForAssessment: 20,
        best_of_scores: "",
      },
      assessmentsFromApi: [],
      assessmentConfigStructure: {},
    };
  },
  validations: {
    assessment: {
      scoreForAssessment: {
        required: requiredIf(function () {
          if (this.assessment.checkAssessment) {
            return true;
          }
        }),
      },
    },
    assessmentConfigStructure: {
      $each: {
        input: {
          required: function () {
            // Validation only applies to inputs with their checkbox checked
            return Object.values(this.assessmentConfigStructure).some(
              (config) => config.checkbox
            );
          },
          // numeric,
          customValidation() {
            if(this.assessment.checkAssessment == false || this.activity_simple_calculation) return true;
            // Custom validation logic
            const checkedAssessments = Object.values(this.assessmentConfigStructure)
              .filter((config) => config.checkbox)
              .map((config) => config.input);

            // If no checkboxes are checked, validation passes
            if (checkedAssessments.length === 0) return true;

            // Ensure valid numerical inputs
            const numericalValues = checkedAssessments.filter(
              (val) => val !== null && val !== "" && !isNaN(val)
            );

            // Check if all values are the same or the sum is 100
            const allSame = numericalValues.every(
              (val) => val === numericalValues[0]
            );
            const sumIs100 =
              numericalValues.reduce((sum, val) => sum + Number(val), 0) === 100;

            return allSame || sumIs100;
          },
        },
      },
    },
  },
  methods: {
    async checkValidation() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      }
    },
    async getAssessments() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/course/${this.courseReport.course_id}/exams`;
      await this.$axios
        .get(url)
        .then((response) => {
          let assessments = [];

          assessments = response.data;
          this.assessmentConfigStructure = {};
          assessments.forEach((assessment) => {
            if (assessment.activity_type == "assessment") {
              this.$set(this.assessmentConfigStructure, assessment.id, {
                checkbox: false,
                input: '', //weigtage
              });
              this.assessmentsFromApi.push({
                id: assessment.id,
                name: assessment.name,
                description: assessment.summary,
                date:
                  assessment.close_at != null
                    ? moment(new Date(assessment.end_at))
                    : null,
                maximum_mark: assessment.maximum_mark,
                course: assessment.course_id,
                respond_after_close:
                  assessment.respond_after_close == 1 ? true : false,
                hidden: assessment.hidden == 1 ? true : false,
              });
            }
          });

          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
  },
};
</script>

<style >
.assessment p {
  font-size: 16px;
  font-weight: 600;
}

.assessment p small {
  font-size: 10px;
  font-weight: 600;
}
.assessment span {
  font-size: 12px;
  font-weight: 600;
}
</style>
