<template>
  <div>
    <div class="textBook">
      <div class="p-4 bg-white rounded shadow">
        <div style="margin-left: -10px; font-size: 13px !important;">
          <b><u>Attainment Calculation Method</u></b>
          <i style="cursor: pointer; margin-left: 10px; font-size: 16px !important; color: #1ea2ed;" class="fas fa-edit"
            @click="toggleEdit"></i>
          <br />
          There are two methods for calculating CO attainment of a Course. Choose one of the methods listed below:
        </div>

        <b-row>
          <!-- Method 1 Info -->
          <b-form-group class="col">
            <i style="font-size: 16px !important; color: #1ea2ed;" @click="$bvModal.show('showMethod1');"
              class="fas fa-info-circle fa-xm"></i>
            <b-modal class="no-print" title="Method 1: Question-Based Targeted Attainment Method (QBTAM)"
              id="showMethod1" size="md" hide-footer centered>
              <p><strong>Explanation:</strong> This method calculates the attainment level for each CO based on the
                percentage of students who achieved the target percentage for individual questions mapped to that CO.
              </p>
              <h5><b><u>Steps:</u></b></h5>
              <p>
                1. The CO mapped question-wise marks of each student for an assessment are noted.
              </p>
              <p>
                2. The marks obtained for each question are converted to a percentage score for that question.
              </p>
              <p>
                3. This percentage marks for each question are then compared with the target percentage of the CO to
                which
                the question is mapped.
              </p>
              <p>
                4. The total number of students who have achieved the CO target for each question is obtained.
              </p>
              <p>
                5. This total number of students who achieved the target is converted to a percentage value.
              </p>
              <p>
                6. This percentage is compared to a threshold value, and the attainment level for each question is
                determined.
              </p>
              <p>
                7. The average value of the attainment level of all questions mapped to the same CO is calculated.
              </p>
              <p>
                8. This average value becomes the attainment value for each CO for that assessment.
              </p>

              <h5><b><u>Advantages:</u></b></h5>
              <p>
                1. Granular Analysis: By assessing attainment at the question level, educators gain detailed insights
                into
                student performance, allowing for targeted interventions.
              </p>
              <p>
                2. Sensitivity to Question Difficulty: This method considers the difficulty level of individual
                questions,
                providing a more nuanced understanding of CO attainment.
              </p>
              <p>
                3. Flexibility: Educators can adjust the target percentage for each CO based on the difficulty level
                of
                questions, allowing for more accurate assessment.
              </p>

              <h5><b><u>Disadvantages:</u></b></h5>
              <p>
                1. Complexity: The method involves analyzing performance at the question level, which may be more
                complex
                compared to Method 2.
              </p>
              <p>
                2. Subjectivity in Setting Target Percentage: Setting target percentages for individual questions
                requires
                careful consideration and may introduce subjectivity into the assessment process.
              </p>
              <b-button variant="danger" size="sm" @click="$bvModal.hide('showMethod1')">Close</b-button>
            </b-modal>
            <label><b>Method 1:</b> Question-Based Targeted Attainment Method (QBTAM)</label>
          </b-form-group>

          <!-- Method 2 Info -->
          <b-form-group class="col">
            <i style="font-size: 16px !important; color: #1ea2ed;" @click="$bvModal.show('showMethod2');"
              class="fas fa-info-circle fa-xm"></i>
            <b-modal class="no-print" title="Method 2: CO-Targeted Average Attainment Method (CTAAM)" id="showMethod2"
              size="md" hide-footer centered>
              <p><strong>Explanation:</strong> This method calculates the attainment level for each CO by averaging the
                percentage marks achieved by students against the target percentage for each CO.</p>
              <h5><b><u>Steps:</u></b></h5>
              <p>
                1. The CO mapped question-wise marks of each student for an assessment are noted.
              </p>
              <p>
                2. The average marks for each CO are calculated from these marks.
              </p>
              <p>
                3. The percentage marks for each CO are calculated based on the average marks.
              </p>
              <p>
                4. The percentage marks for each CO are then compared with the target percentage for each CO.
              </p>
              <p>
                5. The total number of students who have achieved the target for each CO is obtained.
              </p>
              <p>
                6. From this, the percentage of students who achieved the target for each CO is calculated.
              </p>
              <p>
                7. This percentage is compared to a threshold value, and the attainment level for each CO is
                determined.
              </p>
              <h5><b><u>Advantages:</u></b></h5>
              <p>
                1. Simplicity: The method is straightforward and easy to understand, making it accessible to
                educators.
              </p>
              <p>
                2. Focus on Overall CO Performance: By averaging the performance of students across all questions
                mapped
                to a particular CO, this method provides a holistic view of CO attainment.
              </p>
              <p>
                3. Clear Comparison to Target: By comparing the average percentage marks for each CO with the target
                percentage, educators can easily assess whether the COs are being met.
              </p>
              <h5><b><u>Disadvantages:</u></b></h5>
              <p>
                1. Potential Oversimplification: Averaging the marks may oversimplify the assessment, potentially
                masking
                variations in student performance across different questions.
              </p>
              <p>
                2. Lack of Granularity: This method does not provide detailed insights into student performance at the
                question level, which may limit the ability to identify specific areas for improvement.
              </p>
              <p>
                3. Ignoring Question Difficulty: It does not take into account the difficulty level of individual
                questions, which may impact the overall CO attainment assessment.
              </p>
              <b-button variant="danger" size="sm" @click="$bvModal.hide('showMethod2')">Close</b-button>
            </b-modal>
            <label><b>Method 2:</b> CO-Targeted Average Attainment Method (CTAAM)</label>
          </b-form-group>
        </b-row>

        <!-- Selection Table -->
        <b-row>
          <b-col md="12">
            <div v-if="config">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Assignment</th>
                    <th>Assessment</th>
                    <th>Board Exam</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b-form-group label="Choose Equation Type:">
                        <b-form-radio v-model="selectedEquationType.assignment" :value="1" :disabled="!isEditing">Method
                          1</b-form-radio>
                        <b-form-radio v-model="selectedEquationType.assignment" :value="2" :disabled="!isEditing">Method
                          2</b-form-radio>
                      </b-form-group>
                    </td>
                    <td>
                      <b-form-group label="Choose Equation Type:">
                        <b-form-radio v-model="selectedEquationType.assessment" :value="1" :disabled="!isEditing">Method
                          1</b-form-radio>
                        <b-form-radio v-model="selectedEquationType.assessment" :value="2" :disabled="!isEditing">Method
                          2</b-form-radio>
                      </b-form-group>
                    </td>
                    <td>
                      <b-form-group label="Choose Equation Type:">
                        <b-form-radio v-model="selectedEquationType.boardexam" :value="1" :disabled="!isEditing">Method
                          1</b-form-radio>
                        <b-form-radio v-model="selectedEquationType.boardexam" :value="2" :disabled="!isEditing">Method
                          2</b-form-radio>
                      </b-form-group>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>

        <!-- Action Buttons -->
        <b-button variant="danger" @click="clearSelectedMethods()" size="sm" :disabled="!isEditing">Clear all</b-button>
        <b-button variant="info" class="m-2" @click="setAttainmentConfiguration()"
          :disabled="!isEditing">Update</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["usergroup_id"],
  async created() {
    await this.getAttainmentConfiguration();
  },
  data() {
    return {
      config: true,
      isEditing: false, // Track whether editing mode is active
      selectedEquationType: {
        assessment: null,
        assignment: null,
        boardexam: null
      },
    };
  },
  methods: {
    toggleEdit() {
      this.isEditing = !this.isEditing;
      if (!this.isEditing) {
        // Reset the selection if the user exits edit mode
        this.getAttainmentConfiguration();
      }
    },

    clearSelectedMethods() {
        this.selectedEquationType = { assessment: null, assignment: null, boardexam: null };
        this.setAttainmentConfiguration();
    },

    async setAttainmentConfiguration() {
        try {
          const url = `${this.$store.getters.getAPIKey.mainAPI}/usergroups/setting/${this.usergroup_id}/attainment_calculation_method`;
          await this.$axios.post(url, { attainment_calculation_method: this.selectedEquationType });
          this.$toast.success("Updated", { position: "top", duration: 3000 });
          this.isEditing = false; // Disable edit mode after update
        } catch (error) {
          console.error("Error updating attainment configuration:", error);
          this.$toast.error("Update failed!", { position: "top", duration: 3000 });
        }
    },

    async getAttainmentConfiguration() {
      try {
        const url = `${this.$store.getters.getAPIKey.mainAPI}/usergroups/setting/${this.usergroup_id}/attainment_calculation_method`;
        const response = await this.$axios.get(url);

        this.selectedEquationType = {
          assessment: response.data?.assessment ?? null,
          assignment: response.data?.assignment ?? null,
          boardexam: response.data?.boardexam ?? null
        };
      } catch (error) {
        console.error("Error fetching attainment configuration:", error);

        this.selectedEquationType = {
          assessment: null,
          assignment: null,
          boardexam: null
        };
      }
    }
  }
};
</script>

<style scoped>
.textBook .ltp_credit {
  width: 25%;
  display: flex;
  justify-content: space-between;
}

.textBook .ltp_credit input {
  width: 150px;
  text-align: center;
}
</style>
